<template>
  <div :class="$style.root">
    <VToolbar elevation="0" class="mb-2">
      <VTabs  v-model="tab">
        <VTab>Информация по документу</VTab>
        <VTab>Инициатор</VTab>
        <VTab>Маршрут</VTab>
      </VTabs>
    </VToolbar>
    <div class="pt-5">
      <div v-if="tab === 0">
        <VRow>
          <VCol cols="12" md="3">
            <StaticField label="Создан" :value="created" />
          </VCol>
          <VCol cols="12" md="3">
            <StaticField label="Изменен" :value="updated" />
          </VCol>
          <VCol cols="12" md="3">
            <StaticField label="Зарегистрирован в СЭД" />
          </VCol>
          <VCol cols="12" md="3">
            <StaticField label="Номер документа в СЭД" :value="sed" />
          </VCol>
          <VCol cols="12">
            <FileHistory v-for="(items, type) in files" :type="type" :items="items" :key="type" @edit="onEdit" />
          </VCol>
        </VRow>
      </div>
      <div v-if="tab === 1">
        <VRow>
          <VCol cols="12">
            <VRow>
              <VCol cols="12" md="6">
                <StaticField label="Вид работ" :value="work" :items="works" outlined dense hide-details="auto" />
              </VCol>
              <VCol cols="12" md="6">
                <StaticField label="Цех / структурное подразделение" :value="place" :items="places" outlined dense hide-details="auto" />
              </VCol>
              <VCol cols="12" md="6">
                <StaticField label="Номер договора" :value="contract" outlined dense hide-details="auto" />
              </VCol>
              <VCol cols="12" md="6">
                <StaticField label="Номер дополнительного соглашения" :value="agreement" outlined dense hide-details="auto" />
              </VCol>
              <VCol cols="12" md="4">
                <StaticField label="СПП элемент" :value="spp" outlined dense hide-details="auto" />
              </VCol>
              <VCol cols="12" md="4">
                <StaticField label="Заказ ТОРО" :value="topo" outlined dense hide-details="auto" />
              </VCol>
              <VCol cols="12" md="4">
                <StaticField label="МВЗ" :value="mvz" outlined dense hide-details="auto" />
              </VCol>
            </VRow>
            <SectionCard label="Личная информация">
              <VRow>
                <VCol cols="12" md="4">
                  <StaticField label="ФИО" :value="initiator.name" />
                </VCol>
                <VCol cols="12" md="4">
                  <StaticField label="Должность" :value="initiator.position" />
                </VCol>
                <VCol cols="12" md="4">
                  <StaticField label="Телефон" :value="initiator.phone" />
                </VCol>
              </VRow>
            </SectionCard>
            <SectionCard label="Уведомления">
              <VRow>
                <VCol cols="12" md="6">
                  <VTextField label="E-mail инициатора" v-model="payload.initiator.email" outlined dense hideDetails="auto" />
                </VCol>
                <VCol cols="12">
                  <VRow>
                    <VCol cols="auto">
                      <VRadioGroup v-model="payload.notification.type" class="my-0" hide-details>
                        <VRadio v-for="({ text, value }) in notifications" :label="text" :value="value" :key="value" />
                      </VRadioGroup>
                    </VCol>
                    <VCol v-if="oneTimeNotification" cols="auto">
                      <VSelect label="Время уведомления" v-model="payload.notification.time" :items="times" outlined dense hideDetails="auto" />
                    </VCol>
                  </VRow>
                </VCol>
                <VCol cols="12">
                  <VBtn color="primary" class="mr-2" depressed @click="onSave" :disabled="!diff">Сохранить</VBtn>
                </VCol>
              </VRow>
            </SectionCard>
          </VCol>
        </VRow>
      </div>
      <div v-if="tab === 2">
        <VSelect :items="['Текущая версия', 'Маршрут 1', 'Маршрут 2']" value="Текущая версия" outlined dense hide-details />
        <SectionCard label="1. Разработка, Подписание">
          <AcceptanceRouteTable />
        </SectionCard>
        <SectionCard label="2. Структурное подразделение - место проведения работ">
          <AcceptanceRouteTable />
        </SectionCard>
        <SectionCard label="3. Согласование технических специалистов">
          <AcceptanceRouteTable />
        </SectionCard>
        <SectionCard label="4. Согласование ОТиПБ">
          <AcceptanceRouteTable />
        </SectionCard>
      </div>
    </div>
    <VDialog :value="!!edit" @click:outside="edit = null" persistent max-width="900">
      <VCard tile elevation="0">
        <VCardTitle>Исправить замечания</VCardTitle>
        <VCardText>
          <FileEdit v-bind="{...edit}" />
        </VCardText>
      </VCard>
    </VDialog>
    <VFooter app>
      <VProgressLinear v-if="pending" absolute style="top: 0" indeterminate color="primary darken-2" />
      <div class="py-3 grow">
        <VBtn class="mr-6" outlined depressed @click="$router.push({ name: 'actsvr/AcceptanceListView' })">
          <VIcon>mdi-arrow-left</VIcon>
          Назад к списку
        </VBtn>
        <VBtn color="error" class="ml-2 float-right" depressed :disabled="pending">Вернуть на доработку</VBtn>
        <VBtn color="primary" class="mr-2" depressed :disabled="pending">Создать копию согласования</VBtn>
      </div>
    </VFooter>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { get, map, values } from 'lodash-es';
import { NOTIFICATION_TYPES, PLACES, WORKS } from '@/store/actsvr/enums';
import StaticField from '@/components/general/StaticField/StaticField';
import SectionCard from '@/components/actsvr/SectionCard/SectionCard';
import AcceptanceRouteTable from '@/components/actsvr/AcceptanceRouteTable/AcceptanceRouteTable';
import FileHistory from '@/components/actsvr/FileHistory/FileHistory';
import FileEdit from '@/components/actsvr/FileEdit/FileEdit';
export default {
  name: 'AcceptanceDetailReview',
  components: {
    StaticField,
    SectionCard,
    AcceptanceRouteTable,
    FileHistory,
    FileEdit,
  },
  props: {
    id: { type: String },
    place: { type: String },
    work: { type: String },
    status: { type: String },
    topo: { type: String },
    spp: { type: String },
    mvz: { type: String },
    contract: { type: String },
    agreement: { type: String },
    sed: { type: String },
    updated: { type: String },
    created: { type: String },
    contractor: { type: Object },
    initiator: { type: Object },
    files: { type: Object },
    notification: { type: Object },
  },
  data() {
    return {
      tab: 0,
      edit: null,
      payload: this.getPayload(),
    };
  },
  computed: {
    ...mapGetters({
      pending: 'actsvr/pending',
    }),
    diff() {
      return JSON.stringify(this.payload) !== JSON.stringify(this.getPayload());
    },
    works() {
      return values(WORKS);
    },
    places() {
      return values(PLACES);
    },
    times() {
      return map(new Array(24).fill(':00'), (value, index) => ({
        text: index + value,
        value: index,
      }));
    },
    notifications() {
      return values(NOTIFICATION_TYPES);
    },
    oneTimeNotification() {
      return get(NOTIFICATION_TYPES, 'ONE_TIME.value') === get(this.payload, 'notification.type');
    },
  },
  methods: {
    getPayload() {
      return {
        initiator: {
          email: this.initiator.email,
        },
        notification: {
          time: this.notification.time,
          type: this.notification.type,
        },
      };
    },
    onEdit: function(item) {
      this.edit = item;
    },
    onSave: function() {
      this.$notify({
        type: 'success',
        title: 'Успех',
        text: 'Согласование успешно сохранена',
      });
    },
  }
}
</script>

<style module lang="scss">
.root {}
</style>
