<template>
<div :class="$style.root">
  <div v-if="label" :class="$style.title">{{ label }}</div>
  <div :class="$style.inner">
    <VDataTable :headers="headers" :items="items" :item-class="rowClassControl" :mobile-breakpoint="0" show-expand hide-default-footer hide-default-header>
      <template v-slot:item.history="{ item: { history } }">
        Версия {{(history.length || 0) + 1 }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-right">
          <VBtn v-if="item.pending" class="ml-2" depressed x-small disabled>Ожидается поднятие архива</VBtn>
          <template v-else>
            <VBtn v-if="item.value" class="ml-2" depressed x-small>Скачать</VBtn>
            <VBtn v-else class="ml-2" depressed x-small>Запросить из архива</VBtn>
          </template>
          <VBtn v-if="item.message" class="ml-2" depressed x-small @click="$emit('edit', item)">Исправить</VBtn>
        </div>
      </template>
      <template v-slot:expanded-item="{ headers, item: { history } }">
        <td :colspan="headers.length">
          <VTimeline align-top dense>
            <VTimelineItem v-for="(item, index) in history" small :key="item.id">
              <div>
                <div class="font-weight-normal">
                  <strong>{{ item.date }}</strong> @{{ item.author }}
                </div>
                <div>{{ item.message }}</div>
                <div class="mt-2">
                  <VBtn v-if="item.pending" class="mr-2" depressed x-small disabled>Ожидается поднятие архива</VBtn>
                  <template v-else>
                    <VBtn v-if="item.value" class="mr-2" depressed x-small>Скачать версию {{ history.length - index }}</VBtn>
                    <VBtn v-else class="mr-2" depressed x-small>Запросить из архива</VBtn>
                  </template>
                </div>
              </div>
            </VTimelineItem>
          </VTimeline>
        </td>
      </template>
    </VDataTable>
  </div>
</div>
</template>

<script>
import { get } from 'lodash-es';
import { FILE_TYPES } from '@/store/actsvr/enums';
export default {
  name: 'FileHistory',
  props: {
   type: { type: String },
   items: { type: Array },
  },
  computed: {
    label() {
      return get(FILE_TYPES, [this.type, 'text'], '');
    },
    headers() {
      return [
        {value: 'name'},
        {value: 'history'},
        {value: 'date'},
        {value: 'author'},
        {value: 'actions'}
      ];
    },
  },
  methods: {
    rowClassControl({ message }) {
      if(message) return 'error lighten-5';
      return '';
    }
  }
}
</script>

<style module lang="scss">
.root {
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 20px;
}
.title {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
}
.inner {
  td {
    width: 600px;
    &:nth-child(1) {
      width: 1px !important;
    }
    &:last-child {
      width: 1px;
      white-space: nowrap;
    }
  }
}
</style>
