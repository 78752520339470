<template>
  <div :class="$style.root">
    <VForm v-model="valid" lazy-validation ref="form" @submit.prevent="onSave">
      <SectionCard label="1. Общая информация">
        <VRow>
          <VCol cols="12" md="6">
            <VSelect label="Вид работ" v-model="payload.work" :items="works" outlined dense hide-details="auto" />
          </VCol>
          <VCol cols="12" md="6">
            <VSelect label="Цех / структурное подразделение" v-model="payload.place" :items="places" outlined dense hide-details="auto" />
          </VCol>
          <VCol cols="12" md="6">
            <VAutocomplete label="Номер договора" v-model="payload.contract" :items="contracts" outlined dense hide-details="auto" />
          </VCol>
          <VCol cols="12" md="6">
            <VAutocomplete label="Номер дополнительного соглашения" v-model="payload.agreement" :items="agreements" outlined dense hide-details="auto" />
          </VCol>
          <VCol cols="12" md="4">
            <VTextField label="СПП элемент" v-model="payload.spp" outlined dense hide-details="auto" />
          </VCol>
          <VCol cols="12" md="4">
            <VTextField label="Заказ ТОРО" v-model="payload.topo" outlined dense hide-details="auto" />
          </VCol>
          <VCol cols="12" md="4">
            <VTextField label="МВЗ" v-model="payload.mvz" outlined dense hide-details="auto" />
          </VCol>
        </VRow>
      </SectionCard>
      <SectionCard label="2. Документы для согласования">
        <VRow>
          <VCol cols="12">
            <div class="font-weight-bold mb-2">КС-2 / Акт выполненных работ</div>
            <FileFieldMultiple v-model="stub[0]" :filled="stub[0].length > 0" />
            <div class="mt-2">Доступные форматы: pdf. Размер не более 4МБ. Не более 1 файла</div>
          </VCol>
          <VCol cols="12">
            <div class="font-weight-bold mb-2">КС-3 / справка о совместимости выполненных работ и затрат</div>
            <FileFieldMultiple v-model="stub[1]" :filled="stub[0].length > 0" />
            <div class="mt-2">Доступные форматы: pdf. Размер не более 4МБ. Не более 1 файла</div>
          </VCol>
          <VCol cols="12">
            <div class="font-weight-bold mb-2">М35 / Акт об оприходовании материальных ценностей, полученных при разборке и демонтаже зданий и сооружений объектов основанных средств, оборудования и объектов незавершенного строительства</div>
            <FileFieldMultiple v-model="stub[2]" :filled="stub[0].length > 10" />
            <div class="mt-2">Доступные форматы: pdf. Размер не более 4МБ. Не более 10 файлов</div>
          </VCol>
          <VCol cols="12">
            <div class="font-weight-bold mb-2">Приложение к М35</div>
            <FileFieldMultiple v-model="stub[3]" :filled="stub[0].length > 10" />
            <div class="mt-2">Доступные форматы: pdf. Размер не более 4МБ. Не более 10 файла</div>
          </VCol>
          <VCol cols="12">
            <div class="font-weight-bold mb-2">Акт расхода материалов</div>
            <FileFieldMultiple v-model="stub[4]" :filled="stub[0].length > 0" />
            <div class="mt-2">Доступные форматы: pdf. Размер не более 4МБ. Не более 1 файла</div>
          </VCol>
          <VCol cols="12">
            <div class="font-weight-bold mb-2">Приложение к акту расхода материалов</div>
            <FileFieldMultiple v-model="stub[5]" :filled="stub[0].length > 10" />
            <div class="mt-2">Доступные форматы: pdf. Размер не более 4МБ. Не более 10 файла</div>
          </VCol>
        </VRow>
      </SectionCard>
      <SectionCard label="3. Личная информация">
        <VRow>
          <VCol cols="12" md="4">
            <VTextField label="ФИО инициатора" v-model="payload.initiator.name" outlined dense hide-details="auto" />
          </VCol>
          <VCol cols="12" md="4">
            <VTextField label="Телефон инициатора" v-model="payload.initiator.phone" outlined dense hide-details="auto" />
          </VCol>
          <VCol cols="12" md="4">
            <VTextField label="Должность" v-model="payload.initiator.position" outlined dense hide-details="auto" />
          </VCol>
        </VRow>
      </SectionCard>
      <SectionCard label="4. Уведомления">
        <VRow>
          <VCol cols="12" md="6">
            <VTextField label="E-mail инициатора" v-model="payload.initiator.email" outlined dense hideDetails="auto" />
          </VCol>
          <VCol cols="12">
            <VRow>
              <VCol cols="auto">
                <VRadioGroup v-model="payload.notification.type" class="my-0" hide-details>
                  <VRadio v-for="({ text, value }) in notifications" :label="text" :value="value" :key="value" />
                </VRadioGroup>
              </VCol>
              <VCol v-if="oneTimeNotification" cols="auto">
                <VSelect label="Время уведомления" v-model="payload.notification.time" :items="times" outlined dense hideDetails="auto" />
              </VCol>
            </VRow>
          </VCol>
        </VRow>
      </SectionCard>
    </VForm>
    <VFooter app>
      <VProgressLinear v-if="pending" absolute style="top: 0" indeterminate color="primary darken-2" />
      <div class="py-3 grow">
        <VBtn class="mr-6" outlined depressed @click="$router.push({ name: 'actsvr/AcceptanceListView' })">
          <VIcon>mdi-arrow-left</VIcon>
          Назад к списку
        </VBtn>
        <template v-if="diff">
          <VBtn color="primary" class="mr-2" depressed :disabled="pending" @click="onSave">Сохранить как черновик</VBtn>
          <VBtn color="secondary" class="mr-2" depressed :disabled="pending" @click="onReset">Отменить</VBtn>
        </template>
        <VBtn color="success" class="mr-2 float-right" depressed :disabled="pending || !valid" @click="onApprove">Отправить на согласование</VBtn>
      </div>
    </VFooter>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { values, map, get, compact } from 'lodash-es';
import { NOTIFICATION_TYPES, PLACES, WORKS } from '@/store/actsvr/enums';
import SectionCard from '@/components/actsvr/SectionCard/SectionCard';
import FileFieldMultiple from '@/components/general/FileFieldMultiple/FileFieldMultiple';
export default {
  name: 'AcceptanceDetailFrom',
  components: {
    FileFieldMultiple,
    SectionCard,
  },
  props: {
    id: { type: String },
    place: { type: String },
    work: { type: String },
    status: { type: String },
    topo: { type: String },
    spp: { type: String },
    mvz: { type: String },
    contract: { type: String },
    agreement: { type: String },
    sed: { type: String },
    updated: { type: String },
    contractor: { type: Object },
    initiator: { type: Object },
    files: { type: Object },
    notification: { type: Object },
  },
  data() {
    return {
      valid: true,
      payload: this.getPayload(),
      stub: [[], [], [], [], [], []],
    }
  },
  computed: {
    ...mapGetters({
      pending: 'actsvr/pending',
    }),
    works() {
      return values(WORKS);
    },
    places() {
      return values(PLACES);
    },
    times() {
      return map(new Array(24).fill(':00'), (value, index) => ({
        text: index + value,
        value: index,
      }));
    },
    notifications() {
      return values(NOTIFICATION_TYPES);
    },
    contracts() {
      return compact([
        this.contract,
      ]);
    },
    agreements() {
      return compact([
        this.agreement,
      ]);
    },
    oneTimeNotification() {
      return get(NOTIFICATION_TYPES, 'ONE_TIME.value') === get(this.payload, 'notification.type');
    },
    diff() {
      return JSON.stringify(this.payload) !== JSON.stringify(this.getPayload());
    },
  },
  methods: {
    getPayload() {
      return {
        work: this.work,
        place: this.place,
        contract: this.contract,
        agreement: this.agreement,
        topo: this.topo,
        spp: this.spp,
        mvz: this.mvz,
        initiator: {
          name: this.initiator.name,
          phone: this.initiator.phone,
          email: this.initiator.email,
          position: this.initiator.position,
        },
        files: this.files,
        notification: {
          time: this.notification.time,
          type: this.notification.type,
        },
      };
    },
    onSave: function() {
      this.$notify({
        type: 'success',
        title: 'Успех',
        text: 'Согласование успешно сохранена',
      });
    },
    onApprove: function() {
      if (this.$refs.form.validate()) {
        this.$router.push({ name: 'actsvr/AcceptanceListView' });
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Акт успешно отправлен на согласование',
        });
      }
    },
    onReset: function() {
      this.payload = this.getPayload();
    },
  },
  watch: {
    id: {
      immediate: true,
      handler: function() {
        this.onReset();
      }
    }
  }
}
</script>

<style module lang="scss">
.root {}
</style>
