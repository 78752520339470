export const STATUSES = {
  DRAFT: {
    text: 'Черновик',
    value: '1',
  },
  START_NEGOTIATION: {
    text: 'Запуск согласования',
    value: '2',
  },
  SUBMITTED: {
    text: 'Файлы выгружены в ДМЗ',
    value: '3',
  },
  ON_APPROVAL_DRAFT: {
    text: 'Документ создан в СЭД (черновик)',
    value: '4',
  },
  LOADED: {
    text: 'Файлы загружены из ДМЗ в Архив',
    value: '5',
  },
  ON_APPROVAL: {
    text: 'На согласовании',
    value: '6',
  },
  ON_COMPLETION: {
    text: 'На доработке',
    value: '7',
  },
  ON_SIGNING: {
    text: 'На подписании',
    value: '8',
  },
  AGREED: {
    text: 'Подписан',
    value: '9',
  },
  CHECKED_BY_ADMINISTRATOR: {
    text: 'На проверке у администратора',
    value: '10',
  },
  REQUEST_FOR_REVISION: {
    text: 'Запрос на доработку',
    value: '11',
  },
  REMOVED: {
    text: 'Документ удален в СЭД',
    value: '13',
  },
};

export const WORKS = {
  TYPE_1: {
    text: 'Тип работы 1',
    value: '1',
  },
  TYPE_2: {
    text: 'Тип работы 2',
    value: '2',
  },
  TYPE_3: {
    text: 'Тип работы 3',
    value: '3',
  }
};

export const PLACES = {
  PLACE_1: {
    text: 'Цех 1',
    value: '1',
  },
  PLACE_2: {
    text: 'Цех 2',
    value: '2',
  },
  PLACE_3: {
    text: 'Цех 3',
    value: '3',
  }
};

export const FILE_TYPES = {
  KC2: {
    text: 'КС-2 / Акт выполненных работ',
    value: 'KC2',
  },
  KC3: {
    text: 'КС-3 / справка о совместимости выполненных работ и затрат',
    value: 'KC3',
  },
  M35: {
    text: 'М35 / Акт об оприходовании материальных ценностей, полученных при разборке и демонтаже зданий и сооружений объектов основанных средств, оборудования и объектов незавершенного строительства',
    value: 'M35',
  },
  M35_ADDITION: {
    text: 'Приложение к М35',
    value: 'M35_ADDITION',
  },
  CONSUMPTION: {
    text: 'Акт расхода материалов',
    value: 'CONSUMPTION',
  },
  CONSUMPTION_ADDITION: {
    text: 'Приложение к акту расхода материалов',
    value: 'CONSUMPTION_ADDITION',
  },
};

export const NOTIFICATION_TYPES = {
  REGULAR: {
    text: 'Постоянные уведомления',
    value: '2',
  },
  ONE_TIME: {
    text: 'Разовые сообщения',
    value: '1',
  },
  NEVER: {
    text: 'Отказаться от рассылки',
    value: '0',
  },
};
