<template>
  <VDialog max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <Chip :status="value" />
      </div>
    </template>
    <CardHistory />
  </VDialog>
</template>

<script>
import Chip  from './components/Chip';
import CardHistory  from './components/CardHistory';
export default {
  name: 'StatusHistory',
  components: {
    Chip,
    CardHistory,
  },
  props: {
    value: { type: String },
  },

}
</script>

<style scoped>

</style>
