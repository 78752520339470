<template>
  <VCard>
    <VCardTitle>История статусов</VCardTitle>
    <VCardText>
      <VDataTable :headers="headers" :items="items">
        <template v-slot:item.status="{ item: { status } }">
          <Chip :status="status"/>
        </template>
      </VDataTable>
    </VCardText>
  </VCard>
</template>

<script>
import Chip from '../components/Chip';
export default {
  name: 'CardHistory',
  components: {
    Chip,
  },
  computed: {
    headers() {
      return [
        {text: 'Дата', value: 'date'},
        {text: 'Статус', value: 'status'},
      ]
    },
    items() {
      return [
        {date: '22.22.2222', status: '1'},
        {date: '22.22.2222', status: '3'},
        {date: '22.22.2222', status: '9'},
      ]
    }
  }
}
</script>

<style module lang="scss">

</style>
