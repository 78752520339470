<template>
  <button :class="$style.root" :style="{borderColor: color}">
    {{ name }}
  </button>
</template>

<script>
import { STATUSES } from '@/store/actsvr/enums';
import { get, find, findKey } from 'lodash-es';

export default {
  name: 'Chip',
  props: {
    status: { type: String },
  },
  computed: {
    name() {
      return get(find(STATUSES, { value: this.status }), 'text');
    },
    code() {
      return findKey(STATUSES, { value: this.status });
    },
    color() {
      return get({
        DRAFT: '#2b4244', // Черновик
        START_NEGOTIATION: '#2b4244', // Запуск согласования
        SUBMITTED: '#fb8c00', // Отправлен
        ON_APPROVAL: '#fb8c00', // На согласовании
        ON_COMPLETION: '#F44336', // На доработке
        REQUEST_FOR_REVISION: '#FB8C00', // Запрос на доработку
        AGREED: '#4caf50', // Согласован
        CHECKED_BY_ADMINISTRATOR: '#F44336' // На проверке у администратора
      }, this.code, '#2b4244');
    }
  }
}
</script>

<style module lang="scss">
.root {
  padding: 6px 10px;
  border-left-width: 2px;
  border-left-color: #666;
  border-left-style: solid;
}
</style>
