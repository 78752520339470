<template>
  <div :class="$style.root">
    <VDataTable
        hide-default-footer
        :headers="headers"
        :items="items"
        :mobile-breakpoint="0"
    >
      <template v-slot:item.date="{ item: { date } }">
        <Date :value="date" />
      </template>
      <template v-slot:item.status="{ item: { status } }">
        <StatusHistory :value="status" />
      </template>
    </VDataTable>
  </div>
</template>

<script>
import Date from './components/Date';
import StatusHistory from '@/components/actsvr/StatusHistory/StatusHistory';
export default {
  name: 'AcceptanceRouteTable',
  components: {
    StatusHistory,
    Date,
  },
  computed: {
    items() {
      return [
        {
          name: 'Стаметов Алекандр Сегеевич',
          position: 'Главный эколог-начальник службы охраны окружающей среды',
          date: '26.01.2021 10:20:54',
          status: '1',
        },
        {
          name: 'Титоренко Андрей Николаевич',
          position: 'Руководитель структурного подразделения',
          date: '26.01.2021 10:20:54',
          status: '9',
        },
        {
          name: 'Стаметов Алекандр Сегеевич',
          position: 'Главный эколог-начальник службы охраны окружающей среды',
          date: '26.01.2021 10:20:54',
          status: '3',
        },
      ];
    },
    headers() {
      return [
        {
          text: 'ФИО сотрудника',
          value: 'name',
          sortable: false,
        },
        {
          text: 'Должность сотрудника',
          value: 'position',
          sortable: false,
        },
        {
          text: 'Дата',
          value: 'date',
          sortable: false,
        },
        {
          text: 'Статус',
          value: 'status',
          sortable: false,
        },
      ]
    },
  },
}
</script>

<style module lang="scss">
.root {
  th {
    width: 1px !important;
    &:nth-child(n + 5) {
      width: 200px !important;
    }
  }
  td {
    cursor: pointer;
  }
}
</style>
