<template>
  <div>
    {{ formatted[0] }}
    <span v-if="formatted[1]" class="grey--text"> в {{ formatted[1] }}</span>
  </div>
</template>

<script>
export default {
  name: 'Date',
  props: {
    value: { type: String, default: '—' },
  },
  computed: {
    formatted(){
      return this.value ? this.value.split(' ') : '—';
    }
  }
}
</script>

<style module lang="scss">

</style>
