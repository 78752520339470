<template>
<div :class="$style.root">
  <VRow>
    <VCol v-if="message" cols="12">
      <VAlert border="left" colored-border color="primary accent-4" class="mb-0">
        <div class="pl-3">
          <b v-if="author">{{author}}</b>
          <div>{{message}}</div>
          <div :class="$style.file">
            <VBtn icon depressed small  @click="() => {}" title="Скачать">
              <VIcon v-text="'mdi-download'" small />
            </VBtn>
            <b class="ml-2">{{name}}</b>
          </div>
        </div>
      </VAlert>
    </VCol>
    <VCol cols="12">
      <div class="font-weight-bold mb-2">Новый документ с учетом исправлений</div>
      <FileFieldMultiple v-model="values" :filled="values.length > 0" />
      <div class="mt-2">Доступные форматы: pdf. Размер не более 4МБ. Не более 10 файла</div>
    </VCol>
    <VCol cols="12">
      <VRow>
        <VCol cols="12">
          <div class="font-weight-bold mb-2">Комментарий</div>
          <VTextarea auto-grow rows="3"  outlined dense hideDetails="auto" />
        </VCol>
        <VCol cols="12">
          <VRadioGroup class="my-0" hide-details row>
            <VRadio v-for="value in ['Учтено', 'Не учтено', 'Учтено частично']" :label="value" :value="value" :key="value" />
          </VRadioGroup>
        </VCol>
      </VRow>
    </VCol>
  </VRow>
  <VRow>
    <VCol cols="12">
      <VRadioGroup class="my-0" hide-details row>
        <VRadio v-for="value in ['Отправить всем', 'Отправить выдавшим замечание']" :label="value" :value="value" :key="value" />
      </VRadioGroup>
    </VCol>
    <VCol cols="12">
      <VBtn depressed color="primary" large>Отправить на согласование</VBtn>
    </VCol>
  </VRow>
</div>
</template>

<script>
import FileFieldMultiple from '@/components/general/FileFieldMultiple/FileFieldMultiple';
export default {
  name: 'FileEdit',
  components: {
    FileFieldMultiple,
  },
  props: {
    id: { type: String },
    author: { type: String },
    message: { type: String },
    type: { type: String },
    name: { type: String },
    value: { type: String },
    date: { type: String },
    history: { type: Array },
  },
  data() {
    return {
      values: [],
    }
  }
}
</script>

<style module lang="scss">
.root {

}
.file {
  padding: 10px;
  border: 1px dashed #ccc;
  margin-top: 10px;
  display: flex;
  align-items: center;
}
</style>
